import React, {useEffect, useState, Suspense} from "react";
import {
    Route,
    Switch,
} from "react-router-dom";
import {registerLocale} from "react-datepicker";
import {auth} from "./firebase";
import Login from "./login/Login";
import {AppLoader, Navbar} from "./components/Ui";
import {ToastContextProvider} from "./context/ToastContext";
import {AdminDashboard} from "./home-admin/AdminDashboard";
import {GustDashboard} from "./home-guest/GustDashboard";

import {loadMessages, locale} from "devextreme/localization";
import heMessages from "./locale/hebrew.json";
import config from "devextreme/core/config";
import he from "date-fns/locale/he";

import 'react-datepicker/dist/react-datepicker.css';
import "./App.scss";

// Localization
registerLocale("he", he);
loadMessages(heMessages);
locale("he");
config({
    rtlEnabled: true,
});

const App = () => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUser(user);
                setLoading(false);
            } else {
                setUser(null);
                setLoading(false);
            }
        });
        return () => unsubscribe();
    }, []);

    return (
        <div className="App">
            <Suspense fallback={<AppLoader/>}>
                {loading ? <AppLoader/> : <>
                    <Navbar user={user}/>
                    <ToastContextProvider>
                        <Switch>
                            <Route path="/login" component={Login}/>
                            {user
                                ? <Route path="/admin"><AdminDashboard/></Route>
                                : <Route path="/public"><GustDashboard/></Route>
                            }
                        </Switch>
                    </ToastContextProvider>
                </>}
            </Suspense>
        </div>
    );
};

export default App;
